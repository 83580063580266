@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;500;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans+Narrow&family=Quicksand:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans+Narrow&family=Quicksand:wght@300;400;500;600;700&family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap);
/* Quicksand */
/* Raleway */
/* Architects Daughter */
/* PT Sans Narrow */
/* Roboto Slab */
body::-webkit-scrollbar {
  width: 7px;
}

body::-webkit-scrollbar-track {
  background: #F2F3F5;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  border-radius: 20px;
  /* roundness of the scroll thumb */
  background: #46b7ff;
  background: linear-gradient(118deg, #46b7ff 0%, #1970a7 100%);
}

.downArrow {
  position: fixed;
  bottom: 4%;
  left: 2%;
}

.bounce {
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s infinite;
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
}

* {
  scrollbar-width: thin;
  scrollbar-color: #46b7ff #F2F3F5;
}

.svg {
  z-index: 9;
  position: relative;
}
/*# sourceMappingURL=App.css.map */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
/*# sourceMappingURL=index.css.map */
